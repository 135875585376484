<template>
  <div class="body container">
    <el-alert
      type="warning"
      show-icon
      :closable="false"
    >
      <span>{{ $t("message.inactiveTextA") }}</span>
      <router-link :to="{ name: 'Contact' }">
        {{ $t("message.inactiveTextB") }}
      </router-link>
      <span>{{ $t("message.inactiveTextC") }}</span>
    </el-alert>
  </div>
</template>

<script>
export default {
};
</script>


<style scoped>
.body {
  margin-top: 20px;
}
</style>